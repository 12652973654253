window.dropdownEvents = () => {
  const dropdownElements = document.querySelectorAll('.jsDropdown');

  // Check if there is dropdown
  if (dropdownElements.length > 0) {
    dropdownElements.forEach(dropdown => {
      const selected = dropdown.querySelector('.jsDropdownSelected');

      // Toggle show dropdown list
      selected.addEventListener('click', () => {
        dropdown.classList.toggle('active');
      })
    })
  }

  // Hide dropdown when click on other item
  document.addEventListener('click', (e) => {
    if (e.target.classList.value.search('jsDropdownSelected') < 0) {
      const dropdownElements = document.querySelectorAll('.jsDropdown');

      // Check if there is dropdown
      if (dropdownElements.length > 0) {
        dropdownElements.forEach(dropdown => {
          const selected = dropdown.querySelector('.jsDropdownSelected');

          // Hide if dropdown exist and it is open
          dropdown.classList.contains('active') && selected.click();
        })
      }
    }
  });
}