window.contactFormEvents = () => {
  const form = document.querySelector('.jsContactForm'),
    formEmail = form.querySelector('.jsFormEmail'),
    formDesc = form.querySelector('.jsFormDesc'),
    formRodo = form.querySelector('.jsFormRodo'),
    formFields = form.querySelectorAll('.jsFormField'),
    formSubmit = form.querySelector('.jsFormSubmit'),
    contactContainer = $('.jsContact'),
    formContainer = contactContainer.find('.jsContactFormContainer'),
    feedbackContainer = contactContainer.find('.jsContactFeedbackContainer');

  // rodo validation
  formRodo.addEventListener('change', () => {
    formRodo.checked && formRodo.classList.remove('error');
  });

  // live form fields validation
  formFields.forEach(field => {
    field.addEventListener('input', () => {
      field.value === '' ? field.classList.add('error') : field.classList.remove('error');

      if (field.type === 'email' && validateEmail(field.value)) {
        field.classList.remove('error');
      } else if (field.type === 'email' && !validateEmail(field.value)) {
        field.classList.add('error');
      }
    });
  });

  formSubmit.addEventListener('click', (e) => {
    e.preventDefault();
    const email = formEmail.value,
      description = formDesc.value,
      csrftoken = window.getCookie('csrftoken');
    let isFormCorrect = true;

    // rodo validation
    formRodo.checked ? formRodo.classList.remove('error') : formRodo.classList.add('error');

    // form field validation
    formFields.forEach(field => {
      if (field.value === '') {
        field.classList.add('error');
        isFormCorrect = false;
      } else {
        field.classList.remove('error')
      }

      if (field.type === 'email' && !validateEmail(field.value)) {
        field.classList.add('error');
        isFormCorrect = false;
      }
    });

    if (formRodo.checked && isFormCorrect) {
      $.ajax({
        type: 'POST',
        url: '/api/contact-form/',
        headers: {'X-CSRFToken': csrftoken},
        contentType: 'application/json',
        data: JSON.stringify({
          'email': email,
          'description': description,
        }),
        beforeSend: function () {
          formSubmit.setAttribute('disabled', true);
        },
        success: (data) => {
          // reset form
          formEmail.value = '';
          formDesc.value = '';
          formRodo.checked = false;
          formSubmit.removeAttribute('disabled');
          formContainer.slideUp();
          feedbackContainer.slideDown();
        },
        error: (data) => {
          formSubmit.removeAttribute('disabled');
          console.log('failure ' + data);
        },
      });
    }
  })
}