window.cookiesEvents = function (modalContener, btnLater, btnAccept) {
  const cookieModal = document.querySelector(modalContener),
    cookieBtnLater = cookieModal.querySelector(btnLater),
    cookieBtnAccept = cookieModal.querySelector(btnAccept);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    document.querySelector('body').style.overflow = 'unset';
    cookieModal.classList.remove('active');
  }

  function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    const cookie = getCookie("cookie");

    if (cookie === "") {
      document.querySelector('body').style.overflow = 'hidden';
      cookieModal.classList.add('active')
    }
  }

  cookieBtnAccept.addEventListener('click', () => setCookie("cookie", 'active', 30));
  cookieBtnLater.addEventListener('click', () => {
    cookieModal.classList.remove('active')
    document.querySelector('body').style.overflow = 'unset';
  });
  checkCookie();
}