import Swiper from "swiper";

window.boxesServicesSwiper = () => {
  window.boxesServicesIsLoaded = true;
  const sliderBoxesServices = [];
  const windowSizeEvents = () => {
    const boxesServicesModule = document.querySelectorAll('.jsServicesBoxesModule');

    boxesServicesModule.forEach(module => {
      if (window.innerWidth < 768 && window.boxesServicesIsLoaded) {
        window.boxesServicesIsLoaded = false;
        sliderBoxesServices.push(new Swiper('.jsBoxesServicesSwiper', {
          slidesPerView: 1,
          pagination: {
            el: '.jsBoxesServicesPagination',
            clickable: true
          },
        }));
      }
    });
  };

  windowSizeEvents();

  window.addEventListener('resize', () => {
    window.boxesServicesIsLoaded ? windowSizeEvents() : null;
  });
};