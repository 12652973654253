import 'aos';
import lozad from 'lozad';
import './cookies';
import './dropdown';
import './boxesServices';
import './serviceList';
import './validateEmail';
import './contactForm';

const observer = lozad();
observer.observe();

const changeLangHandler = event => {
  event.preventDefault();
  let langCode = $('select[name="language"]').val();
  window.location.href = '/' + langCode;
};

window.generalHandler = () => {
  function autoResizeTextarea() {
    // Targets all textareas with class "textarea-autosize"
    let textareas = document.querySelectorAll('.textarea-autosize'),
      hiddenDiv = document.createElement('div'),
      content = null;

// Add the "textarea-autosize" styles, which are common to both textarea and hiddendiv
    hiddenDiv.classList.add('textarea-autosize');

// Add the styles for the hidden div
    hiddenDiv.style.display = 'none';
    hiddenDiv.style.whiteSpace = 'pre-wrap';
    hiddenDiv.style.wordWrap = 'break-word';

// Loop through all the textareas and add the event listener
    for (let i of textareas) {
      (function (i) {
        // Note: Use 'keyup' instead of 'input'
        // if you want older IE support
        i.addEventListener('input', function () {

          // Append hiddendiv to parent of textarea, so the size is correct
          i.parentNode.appendChild(hiddenDiv);

          // Remove this if you want the user to be able to resize it in modern browsers
          i.style.resize = 'none';

          // This removes scrollbars
          i.style.overflow = 'hidden';

          // Every input/change, grab the content
          content = i.value;

          // Add the same content to the hidden div

          // This is for old IE
          content = content.replace(/\n/g, '<br>');

          // The <br ..> part is for old IE
          // This also fixes the jumpy way the textarea grows if line-height isn't included
          hiddenDiv.innerHTML = content + '<br style="line-height: 3px;">';

          // Briefly make the hidden div block but invisible
          // This is in order to read the height
          hiddenDiv.style.visibility = 'hidden';
          hiddenDiv.style.display = 'block';
          i.style.height = hiddenDiv.offsetHeight + 'px';

          // Make the hidden div display:none again
          hiddenDiv.style.visibility = 'visible';
          hiddenDiv.style.display = 'none';
        });
      })(i);
    }
  }

  function navigationEvents() {
    const navbar = document.querySelector('.jsNavbar');
    const menuBtn = navbar.querySelector('.jsNavbarMenuBtn');

    menuBtn.addEventListener('click', (e) => {
      e.target.checked ? navbar.classList.add('active') : navbar.classList.remove('active');
    });
  }

  function addBackground() {
    let topElement = $('.script-top, .details__top');
    if (window.innerWidth < 600) {
      let imageMobile = topElement.data('mobile-image');
      if (imageMobile) {
        topElement.css('background-image', 'url(' + imageMobile + ')');
      }
    }
  }

  function resizeVideo() {
    let $allVideos = $('video.case__video');
    let windowWidth = $(window).width();
    $allVideos.each(function () {
        this.addEventListener('loadeddata', function () {
          resizeVideo();
        }, false);
        let idVideo = $(this).data('url');
        let heightVideo = $(this).outerHeight() - 10;
        $(this).attr('height', heightVideo);
        $(this).parent().css('height', heightVideo + 'px');
        if (windowWidth > 1200) {
          $(this).parent().parent().css('height', heightVideo + 'px');
        } else {
          let heightDescribe = $('div[data-movie-url="' + idVideo + '"]').outerHeight() - 10;
          $(this).parent().parent().css('height', heightDescribe + heightVideo + 'px');
        }
      }
    );
  }

  function fullpageEvents() {
    const mobile = 767,
      fullpageSection = document.querySelectorAll('.jsFullpageSection');

    fullpageSection.forEach((section) => {
      const imageContainer = section.querySelector('.jsFullpageImageContainer');

      if (imageContainer) {
        const imageSrcDesktop = imageContainer.getAttribute('data-src'),
          imageAltDesktop = imageContainer.getAttribute('data-alt'),
          imageSrcMobile = imageContainer.getAttribute('data-src-mobile'),
          imageAltMobile = imageContainer.getAttribute('data-alt-mobile'),
          image = section.querySelector('.jsFullpageImage');

        // Change image if mobile version is added
        if (imageSrcMobile !== null) {
          if (window.innerWidth > mobile) {
            image.setAttribute('src', imageSrcDesktop)
            image.setAttribute('alt', imageAltDesktop)
          } else {
            image.setAttribute('src', imageSrcMobile)
            image.setAttribute('alt', imageAltMobile)
          }
        }
      }
    })
  }

  $(document).ready(() => {
    window.onresize = function () {
      resizeVideo();
      addBackground();
      fullpageEvents();
    };
    let body = document.getElementById('body');
    let logo = document.getElementById('logo');
    $(document).on('click', '.menu-button-container', () => {
      body.classList.toggle('overflow-hidden');
      logo.classList.toggle('logo-absolute');
    });

    let showMore = document.getElementById('show-more');
    $(document).on('click', '.show-more', () => {
      showMore.classList.toggle('open');
      if ($('#show-more').hasClass('open')) {
        $('.show-more span').html('Hide');
      } else {
        $('.show-more span').html('Show more');
      }
    });

    const observer = lozad();
    observer.observe();
    AOS.init({
      once: true
    });
    addBackground();
    (document.querySelector('select[name="language"]') !== null) ? (document.querySelector('select[name="language"]').onchange = changeLangHandler) : null;
  });
  window.onload = function () {
    dropdownEvents();
    cookiesEvents('.jsModalRodo','.jsModalRodoBtnLater','.jsModalRodoBtnAccept');
    resizeVideo();
    navigationEvents();
    autoResizeTextarea();
  };
};

window.startInteractive = (effectImages) => {
  window.effectImages = effectImages;

  function addInteractive() {

    if (window.innerWidth > 600) {
      let theScript = document.createElement('script');
      theScript.type = 'text/javascript';
      theScript.src = `/static/interactive.${__webpack_hash__}.js`;

      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(theScript, s);
    }
  }

  $(document).ready(() => {
    addInteractive();
  });
};

window.getCookie = function (name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}
